.ActionsContainer{
    display:flex;
    margin-left:70px;
    margin-right:70px;
    margin-left:auto;
    margin-right:auto;
    margin-top:40px;
    padding-bottom:37px;
    border-bottom: 1px solid #999;
    margin-bottom:30px;
}