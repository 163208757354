.HeaderContainer {
    background-image:  linear-gradient(#000911, #06294B);
    background-repeat: no-repeat;
    height:606px;
    color:#FFFFFF;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.HeaderContent{
    width: 1235px;
    margin-left:auto;
    margin-right:auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-image: url('../../Assets/Picture_water.png');
    background-repeat: no-repeat;
    background-position-x: 100%;
}