.Menu {
    display:flex;
}

.MenuItem:hover{
    color:#009EE0;
    cursor: context-menu;
}

.MenuItem{
    margin-left:10px;
    margin-right:10px;
    font-size:20px;
    color: white;
    text-decoration: none;
}