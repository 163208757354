body,html{
  height:100%;
  background-color:#E5E5E5;
  font-family: Saira Condensed;
}

:global(.slick-next:before),
:global(.slick-prev:before){
  color:#999;
}

:global(.App){
  min-width: 1350px;
}