.HeaderTop{
    display:flex;
}

.HeaderTop img{
    margin-top: 38px;
    margin-left: 117px;
}

.HeaderTopContainer{
    margin-left:auto;
    margin-right:138px;
    margin-top: 41px;
}