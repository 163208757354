.HeaderMessage {
    font-size: 36px;
    margin-top: auto;
    margin-bottom: auto;
}

.HeaderMessage div{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    width:667px;
    text-align: left;
    margin-top: auto;
    margin-left: 140px;
    position: absolute;
}

.ButtonContainer{
    margin-top: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}

.ButtonSection{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
}

.Circle{
    height:15px;
    width:15px;
    background:#FFFFFF;
    border-radius: 50%;
    margin-right: 6.5px;
    margin-left: 6.5px;
    cursor:pointer;
}

.Circle.Active{
    background:#009EE0;
}


.TextContainer {
    transition: opacity 0.5s linear;
    opacity:0;
}

.TextContainer.Active{
    opacity:1;
}

/* .TextContainer:not(.Active){
    height:0px;
} */