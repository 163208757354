body,html{
  height:100%;
  background-color:#E5E5E5;
  font-family: Saira Condensed;
}

.slick-next:before,
.slick-prev:before{
  color:#999;
}

.App{
  min-width: 1350px;
}
.Header__HeaderContainer_16mqs {
    background-image:  linear-gradient(#000911, #06294B);
    background-repeat: no-repeat;
    height:606px;
    color:#FFFFFF;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.Header__HeaderContent_1HKCz{
    width: 1235px;
    margin-left:auto;
    margin-right:auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-image: url(/static/media/Picture_water.d847f35d.png);
    background-repeat: no-repeat;
    background-position-x: 100%;
}
.HeaderTop__HeaderTop_4w-NA{
    display:flex;
}

.HeaderTop__HeaderTop_4w-NA img{
    margin-top: 38px;
    margin-left: 117px;
}

.HeaderTop__HeaderTopContainer_2Lj6U{
    margin-left:auto;
    margin-right:138px;
    margin-top: 41px;
}
.Contact__HeaderContact_2RN85{
    display:flex;
    font-size:20px;
    justify-content: flex-end;
    margin-right: 20px;
}

.Contact__HeaderContact_2RN85 div{
    margin-left:10px;
}

.Contact__HeaderContact_2RN85 svg{
    font-size:12px;
}
.Menu__Menu_3qXrj {
    display:flex;
}

.Menu__MenuItem_35r-J:hover{
    color:#009EE0;
    cursor: context-menu;
}

.Menu__MenuItem_35r-J{
    margin-left:10px;
    margin-right:10px;
    font-size:20px;
    color: white;
    text-decoration: none;
}
.HeaderDetails__HeaderMessage_1e0Z7 {
    font-size: 36px;
    margin-top: auto;
    margin-bottom: auto;
}

.HeaderDetails__HeaderMessage_1e0Z7 div{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    width:667px;
    text-align: left;
    margin-top: auto;
    margin-left: 140px;
    position: absolute;
}

.HeaderDetails__ButtonContainer_1iIob{
    margin-top: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}

.HeaderDetails__ButtonSection_1PgVC{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
}

.HeaderDetails__Circle_1NH2e{
    height:15px;
    width:15px;
    background:#FFFFFF;
    border-radius: 50%;
    margin-right: 6.5px;
    margin-left: 6.5px;
    cursor:pointer;
}

.HeaderDetails__Circle_1NH2e.HeaderDetails__Active_3s6rr{
    background:#009EE0;
}


.HeaderDetails__TextContainer_3S7dS {
    transition: opacity 0.5s linear;
    opacity:0;
}

.HeaderDetails__TextContainer_3S7dS.HeaderDetails__Active_3s6rr{
    opacity:1;
}

/* .TextContainer:not(.Active){
    height:0px;
} */
.Container__Container_23g-5{
    background:#FFFFFF;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.Container__ContainerInner_1PAJ4{
    width: 1235px;
    margin-left:auto;
    margin-right:auto;
    background-image: url(/static/media/Water_right.3d2024ee.png), url(/static/media/Water_left.f55cc866.png);
    background-repeat: no-repeat;
    background-position-x: 780px, 0px;
    background-position-y: 380px, 720px;
}
.About__AboutContainer_3SjDO{
    margin-top: 62px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.About__AboutInfo_fbFhL{
    text-align: -webkit-center;
    font-size:20px;
}

.About__Title_2ywwX{
    color:#06284A;
    font-size:28px;
    font-weight:bold;
    text-align: -webkit-center;
}
.Actions__ActionsContainer_10H2b{
    display:flex;
    margin-left:70px;
    margin-right:70px;
    margin-left:auto;
    margin-right:auto;
    margin-top:40px;
    padding-bottom:37px;
    border-bottom: 1px solid #999;
    margin-bottom:30px;
}
.Action__ActionContainer_1SZWp{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 18%;
}

.Action__ActionContainer_1SZWp svg{
    color:#06294B;
    font-size:48px;
}
.Services__ServicesContainer_1xHpl{
    margin-left:auto;
    margin-right:auto;
}

.Services__ServicesInfo_Lbub6{
    text-align: -webkit-center;
    font-size:20px;
}

.Services__Title_3nzZw{
    color:#06284A;
    font-size:28px;
    font-weight:bold;
    text-align: -webkit-center;
}

.Services__ServicesListContainer_1KET_{
    margin-top: 36px;
    display: inline-grid;
    display: flex;
    flex-wrap: wrap;
    margin-left:auto;
    margin-right:auto;
}

.Services__Error_6CZVF{
    color: red;
    text-align: center;
    font-weight: bold;
}
.Service__ServiceContainer_3uEo3{
    background:#06294B;
    color:#FFF;
    height:200px;
    /* margin:1%;
    width:18%; */
    width:227px;
    margin:10px;
    text-align:center;
    display: grid;
}

.Service__ServiceContainer_3uEo3 div.Service__InnerBox_r9P1y{
    margin-top:auto;
    margin-bottom:auto;
}

.Service__ServiceBoxDarker_1Cu1y{
    background-color:#00172D;
}
.Projects__Title_2r7D6{
    color:#06284A;
    font-size:28px;
    font-weight:bold;
    text-align: -webkit-center;
}

.Projects__ProjectsContainer_1dTTM{
    margin-top:70px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:62px;
}

.Projects__ProjectsListContainer_1scNF{
    margin-top:49px;
}

.Projects__Img_3W4xX{
    height: 248px;
    width: 248px;
    margin: auto;
    object-fit: cover;
}

.Projects__Rot90_v9K3G{
    transform:rotate(90deg);
    -webkit-transform:rotate(90deg);
}

.Projects__Rot180_1ZbWq{
    transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
}

.Projects__Rot270_2H-Cw{
    transform:rotate(270deg);
    -webkit-transform:rotate(270deg);
}

.Projects__ImgBig_LsWpT{
    max-width: 90vh;
    max-height: 90vh;
    margin:auto;
}
.ImgViewer__BackDrop_2iXbo{
    transition: all 0.5s ease-in-out 0s;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
}

.ImgViewer__BackDrop_2iXbo.ImgViewer__isActive_lxIvq {
    opacity: 1;
    pointer-events: auto;
}

.ImgViewer__BackDrop_2iXbo.ImgViewer__isActive_lxIvq img{
    opacity:1;
    z-index: 1003;
}

.ImgViewer__Overlay_3hPLH{
    opacity: 0.75;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1002;
    background: none repeat scroll 0 0 #000;
}
/* background-color:#999; */
.Contacts__ContactsContainer_3FnIE{
    border-top: 1px solid #999;
    width: 100%;
    padding-bottom:84px;
    background-color:#fff;
}

.Contacts__Title_3opaZ{
    padding-top:47px;
    color:#06284A;
    font-size:28px;
    font-weight:bold;
    text-align: -webkit-center;
}

.Contacts__InfoContainer_mmJYf{
    margin-top:29px;
    display:flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left:auto;
    margin-right:auto;
}

.Contacts__InfoBox_26stx{
    margin-left: 44px;
    margin-right: 44px;
}

.Contacts__Email_1-lu9{
    color:#009EE0;
    font-size:26px;
    text-decoration: underline;
    line-height:26px;
}

.Contacts__Text20_1d_ec{
    font-size:20px;
    line-height:20px;
}

.Contacts__Text24_3aCYA{
    font-size:24px;
    line-height:24px;
}

.Contacts__InfoBoxTitle_sbBq9{
    color:#06284A;
    font-weight:bold;
    font-size:28px;
    line-height:28px;
}
