.Container{
    background:#FFFFFF;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.ContainerInner{
    width: 1235px;
    margin-left:auto;
    margin-right:auto;
    background-image: url('../../Assets/Water_right.png'), url('../../Assets/Water_left.png');
    background-repeat: no-repeat;
    background-position-x: 780px, 0px;
    background-position-y: 380px, 720px;
}