.ActionContainer{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 18%;
}

.ActionContainer svg{
    color:#06294B;
    font-size:48px;
}