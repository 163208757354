.Title{
    color:#06284A;
    font-size:28px;
    font-weight:bold;
    text-align: -webkit-center;
}

.ProjectsContainer{
    margin-top:70px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:62px;
}

.ProjectsListContainer{
    margin-top:49px;
}

.Img{
    height: 248px;
    width: 248px;
    margin: auto;
    object-fit: cover;
}

.Rot90{
    transform:rotate(90deg);
    -webkit-transform:rotate(90deg);
}

.Rot180{
    transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
}

.Rot270{
    transform:rotate(270deg);
    -webkit-transform:rotate(270deg);
}

.ImgBig{
    max-width: 90vh;
    max-height: 90vh;
    margin:auto;
}