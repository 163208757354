.AboutContainer{
    margin-top: 62px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.AboutInfo{
    text-align: -webkit-center;
    font-size:20px;
}

.Title{
    color:#06284A;
    font-size:28px;
    font-weight:bold;
    text-align: -webkit-center;
}