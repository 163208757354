.ServicesContainer{
    margin-left:auto;
    margin-right:auto;
}

.ServicesInfo{
    text-align: -webkit-center;
    font-size:20px;
}

.Title{
    color:#06284A;
    font-size:28px;
    font-weight:bold;
    text-align: -webkit-center;
}

.ServicesListContainer{
    margin-top: 36px;
    display: inline-grid;
    display: flex;
    flex-wrap: wrap;
    margin-left:auto;
    margin-right:auto;
}

.Error{
    color: red;
    text-align: center;
    font-weight: bold;
}