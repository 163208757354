.HeaderContact{
    display:flex;
    font-size:20px;
    justify-content: flex-end;
    margin-right: 20px;
}

.HeaderContact div{
    margin-left:10px;
}

.HeaderContact svg{
    font-size:12px;
}