.ContactsContainer{
    border-top: 1px solid #999;
    width: 100%;
    padding-bottom:84px;
    background-color:#fff;
}

.Title{
    padding-top:47px;
    color:#06284A;
    font-size:28px;
    font-weight:bold;
    text-align: -webkit-center;
}

.InfoContainer{
    margin-top:29px;
    display:flex;
    width: fit-content;
    margin-left:auto;
    margin-right:auto;
}

.InfoBox{
    margin-left: 44px;
    margin-right: 44px;
}

.Email{
    color:#009EE0;
    font-size:26px;
    text-decoration: underline;
    line-height:26px;
}

.Text20{
    font-size:20px;
    line-height:20px;
}

.Text24{
    font-size:24px;
    line-height:24px;
}

.InfoBoxTitle{
    color:#06284A;
    font-weight:bold;
    font-size:28px;
    line-height:28px;
}