.BackDrop{
    transition: all 0.5s ease-in-out 0s;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
}

.BackDrop.isActive {
    opacity: 1;
    pointer-events: auto;
}

.BackDrop.isActive img{
    opacity:1;
    z-index: 1003;
}

.Overlay{
    opacity: 0.75;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1002;
    background: none repeat scroll 0 0 #000;
}
/* background-color:#999; */