.ServiceContainer{
    background:#06294B;
    color:#FFF;
    height:200px;
    /* margin:1%;
    width:18%; */
    width:227px;
    margin:10px;
    text-align:center;
    display: grid;
}

.ServiceContainer div.InnerBox{
    margin-top:auto;
    margin-bottom:auto;
}

.ServiceBoxDarker{
    background-color:#00172D;
}